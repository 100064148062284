import React, { useEffect, useState } from "react";
import Moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import styles from "./styles.css";

function formatDate(date) {
  const newDate = new Date(date);
  const currentMonth = newDate.getMonth() + 1;
  const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
  const currentDate = newDate.getDate();
  const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
  return `${newDate.getFullYear()}-${monthString}-${dateString}`;
}

function formatDateForGrid(date) {
  if (date.slice(-2) === "01") {
    const newDate = new Date(date);
    const currentMonth = newDate.getMonth() + 2;
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = 1;
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    Moment.locale("en");
    return Moment(
      `${newDate.getFullYear()}-${monthString}-${dateString}`
    ).format("MMM D, YYYY (ddd)");
  } else {
    const newDate = new Date(date);
    const currentMonth = newDate.getMonth() + 1;
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = newDate.getDate() + 1;
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    Moment.locale("en");
    return Moment(
      `${newDate.getFullYear()}-${monthString}-${dateString}`
    ).format("MMM D, YYYY (ddd)");
  }
}

function getDropdownDates() {
  const [dropdownDates, setDropdownDates] = useState([]);

  useEffect(() => {
    async function getDates() {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "game/dates"
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      setDropdownDates(await response.json());
    }

    getDates();
  }, []);

  //finalList = dateList.map((record) => (record.startsWith("Week")?(<optgroup></optgroup>):()))
  return dropdownDates.map((week) => (
    <optgroup className="btn-light" label={"Week " + week._id}>
      {week.dates.map((date) => (
        <option className="btn-light">{formatDateForGrid(date)}</option>
      ))}
    </optgroup>
  ));
}

function getNextDate() {
  const gameDates = [
    "2024-08-25",
    "2024-08-30",
    "2024-08-31",
    "2024-09-01",
    "2024-09-02",
    "2024-09-03",
    "2024-09-07",
    "2024-09-08",
    "2024-09-13",
    "2024-09-14",
    "2024-09-15",
    "2024-09-20",
    "2024-09-21",
    "2024-09-22",
    "2024-09-27",
    "2024-09-28",
    "2024-09-29",
    "2024-10-04",
    "2024-10-05",
    "2024-10-06",
    "2024-10-09",
    "2024-10-10",
    "2024-10-11",
    "2024-10-12",
    "2024-10-13",
    "2024-10-16",
    "2024-10-17",
    "2024-10-18",
    "2024-10-19",
    "2024-10-20",
    "2024-10-23",
    "2024-10-24",
    "2024-10-25",
    "2024-10-26",
    "2024-10-27",
    "2024-10-30",
    "2024-10-31",
    "2024-11-01",
    "2024-11-02",
    "2024-11-03",
    "2024-11-06",
    "2024-11-07",
    "2024-11-08",
    "2024-11-09",
    "2024-11-10",
    "2024-11-13",
    "2024-11-14",
    "2024-11-15",
    "2024-11-16",
    "2024-11-17",
    "2024-11-20",
    "2024-11-21",
    "2024-11-22",
    "2024-11-23",
    "2024-11-24",
    "2024-11-27",
    "2024-11-29",
    "2024-11-30",
    "2024-12-01",
    "2024-12-07",
    "2024-12-08",
  ];

  const today = new Date();
  let closest = Infinity;
  gameDates.forEach(function(d) {
    const date = new Date(d);
    if (date >= today && (date < new Date(closest) || date < closest)) {
      closest = d;
    }
  });

  return closest;
}

const Game = (props) => (
  <tr>
    <td>{formatDate(props.game.date)}</td>
    <td>{props.game.print_time}</td>
    <td>{props.game.away_team}</td>
    <td>{props.game.home_team}</td>
    <td>{props.game.outlet}</td>
  </tr>
);

export default function GameList() {
  const [games, setGames] = useState([]);
  const [uniqueOutlets, setUniqueOutlets] = useState([]);
  const [date, setDate] = useState(formatDate(getNextDate()));
  //const [date, setDate] = useState(formatDate(new Date()));

  const tz = {
    "Eastern Daylight Time": "E",
    "Eastern Standard Time": "E",
    "Central Daylight Time": "C",
    "Central Standard Time": "C",
    "Mountain Daylight Time": "M",
    "Mountain Standard Time": "P",
    "Pacific Daylight Time": "P",
    "Pacific Standard Time": "P",
  };
  const toTZAbbreviation = (input) => tz[input];
  const [timezone, setTimezone] = useState(
    toTZAbbreviation(new Date().toString().match(/\(([^\)]+)\)$/)[1])
      ? toTZAbbreviation(new Date().toString().match(/\(([^\)]+)\)$/)[1])
      : "E"
  );

  // This method fetches the games from the database.
  useEffect(() => {
    async function getGames() {
      setUniqueOutlets([]);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "game/date/" + date
      );
      //const response = await fetch(
      //  `https://cfb-grid-server-ceb260a241df.herokuapp.com/game/date/` + date, {mode:'cors'}
      //);
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const games = await response.json();
      setGames(games);
    }
    getGames();

    return;
  }, [games.length, date]);

  // This method will hide channels with no games
  function hideChannels() {
    games.map((game) => {
      if (uniqueOutlets.indexOf(game.outlet) === -1) {
        uniqueOutlets.push(game.outlet);
      }
    });
  }

  // This method will map out the games on the table
  function gameList() {
    // return games.map((game) => {
    //   return <Game game={game} key={game._id} />;
    // });

    hideChannels();

    let gridList = [
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "11:00 AM";
              case "C":
                return "10:00 AM";
              case "M":
                return "9:00 AM";
              case "P":
                return "8:00 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "11:30 AM";
              case "C":
                return "10:30 AM";
              case "M":
                return "9:30 AM";
              case "P":
                return "8:30 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "12:00 PM";
              case "C":
                return "11:00 AM";
              case "M":
                return "10:00 AM";
              case "P":
                return "9:00 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "12:30 PM";
              case "C":
                return "11:30 AM";
              case "M":
                return "10:30 AM";
              case "P":
                return "9:30 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "1:00 PM";
              case "C":
                return "12:00 PM";
              case "M":
                return "11:00 AM";
              case "P":
                return "10:00 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "1:30 PM";
              case "C":
                return "12:30 PM";
              case "M":
                return "11:30 AM";
              case "P":
                return "10:30 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "2:00 PM";
              case "C":
                return "1:00 PM";
              case "M":
                return "12:00 PM";
              case "P":
                return "11:00 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "2:30 PM";
              case "C":
                return "1:30 PM";
              case "M":
                return "12:30 PM";
              case "P":
                return "11:30 AM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "3:00 PM";
              case "C":
                return "2:00 PM";
              case "M":
                return "1:00 PM";
              case "P":
                return "12:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "3:30 PM";
              case "C":
                return "2:30 PM";
              case "M":
                return "1:30 PM";
              case "P":
                return "12:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "4:00 PM";
              case "C":
                return "3:00 PM";
              case "M":
                return "2:00 PM";
              case "P":
                return "1:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "4:30 PM";
              case "C":
                return "3:30 PM";
              case "M":
                return "2:30 PM";
              case "P":
                return "1:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "5:00 PM";
              case "C":
                return "4:00 PM";
              case "M":
                return "3:00 PM";
              case "P":
                return "2:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "5:30 PM";
              case "C":
                return "4:30 PM";
              case "M":
                return "3:30 PM";
              case "P":
                return "2:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "6:00 PM";
              case "C":
                return "5:00 PM";
              case "M":
                return "4:00 PM";
              case "P":
                return "3:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "6:30 PM";
              case "C":
                return "5:30 PM";
              case "M":
                return "4:30 PM";
              case "P":
                return "3:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "7:00 PM";
              case "C":
                return "6:00 PM";
              case "M":
                return "5:00 PM";
              case "P":
                return "4:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "7:30 PM";
              case "C":
                return "6:30 PM";
              case "M":
                return "5:30 PM";
              case "P":
                return "4:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "8:00 PM";
              case "C":
                return "7:00 PM";
              case "M":
                return "6:00 PM";
              case "P":
                return "5:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "8:30 PM";
              case "C":
                return "7:30 PM";
              case "M":
                return "6:30 PM";
              case "P":
                return "5:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "9:00 PM";
              case "C":
                return "8:00 PM";
              case "M":
                return "7:00 PM";
              case "P":
                return "6:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "9:30 PM";
              case "C":
                return "8:30 PM";
              case "M":
                return "7:30 PM";
              case "P":
                return "6:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "10:00 PM";
              case "C":
                return "9:00 PM";
              case "M":
                return "8:00 PM";
              case "P":
                return "7:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "10:30 PM";
              case "C":
                return "9:30 PM";
              case "M":
                return "8:30 PM";
              case "P":
                return "7:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "11:00 PM";
              case "C":
                return "10:00 PM";
              case "M":
                return "9:00 PM";
              case "P":
                return "8:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "11:30 PM";
              case "C":
                return "10:30 PM";
              case "M":
                return "9:30 PM";
              case "P":
                return "8:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "12:00 AM";
              case "C":
                return "11:00 PM";
              case "M":
                return "10:00 PM";
              case "P":
                return "9:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "12:30 AM";
              case "C":
                return "11:30 PM";
              case "M":
                return "10:30 PM";
              case "P":
                return "9:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "1:00 AM";
              case "C":
                return "12:00 AM";
              case "M":
                return "11:00 PM";
              case "P":
                return "10:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "1:30 AM";
              case "C":
                return "12:30 AM";
              case "M":
                return "11:30 PM";
              case "P":
                return "10:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "2:00 AM";
              case "C":
                return "1:00 AM";
              case "M":
                return "12:00 AM";
              case "P":
                return "11:00 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
      [
        <th className="date__time">
          {(() => {
            switch (timezone) {
              case "E":
                return "2:30 AM";
              case "C":
                return "1:30 AM";
              case "M":
                return "12:30 AM";
              case "P":
                return "11:30 PM";
            }
          })()}
        </th>,
        <td
          className={`${
            uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("NFL NET") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("CW NETWORK") ? "channel" : "channel-hidden"
          }`}
        ></td>,
        <td
          className={`${
            uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
          }`}
        ></td>,
      ],
    ];

    for (const game in games) {
      if (
        games.hasOwnProperty(game) &&
        games[game].timezone === timezone &&
        games[game].col !== 18
      ) {
        gridList[games[game].start - 1][games[game].col] = (
          <td
            className="logo__box border border-dark"
            rowSpan={2}
            style={{ background: games[game].away_color }}
          >
            {games[game].away_rank !== null ? (
              <span className="away__rank">{`${games[game].away_rank}`}</span>
            ) : (
              ""
            )}
            <img
              className="team__logo"
              src={`/logos/${games[game].away_id}.png`}
            />
            <span className="away__record">{`${games[game].away_record}`}</span>
          </td>
        );
        gridList[games[game].start][games[game].col] = "";
        gridList[games[game].start + 1][games[game].col] = (
          <td className="border border-dark game__box" rowSpan={2}>
            {`${games[game].away_team}`}
            <br />
            {`${games[game].time} - ${(() => {
              switch (games[game].outlet) {
                case "NFL NET":
                  return "NFLN";
                case "CW NETWORK":
                  return "CW";
                default:
                  return games[game].outlet;
              }
            })()}`}
            <br />
            {`${games[game].home_team}`}
          </td>
        );
        gridList[games[game].start + 2][games[game].col] = "";
        gridList[games[game].start + 3][games[game].col] = (
          <td
            className="logo__box border border-dark"
            rowSpan={2}
            style={{ background: games[game].home_color }}
          >
            {games[game].home_rank !== null ? (
              <span className="home__rank">{`${games[game].home_rank}`}</span>
            ) : (
              ""
            )}
            <img
              className="team__logo"
              src={`/logos/${games[game].home_id}.png`}
            />

            <span className="home__record">{`${games[game].home_record}`}</span>
          </td>
        );
        gridList[games[game].start + 4][games[game].col] = "";
      }
    }

    const earliestTime = Math.min.apply(
      null,
      games.map((v) => v.start)
    );
    const latestTime = Math.max.apply(
      null,
      games.map((v) => v.start)
    );

    gridList.splice(latestTime + 5);
    gridList.splice(gridList[earliestTime - 1], earliestTime - 1);

    let rowList = gridList.map((grid) => <tr>{grid}</tr>);
    return rowList;
  }

  function streamList() {
    let streamList = [];

    for (const game in games) {
      if (
        games.hasOwnProperty(game) &&
        games[game].timezone === timezone &&
        games[game].col === 18
      ) {
        streamList.push(
          <table className="d-inline-block p-2">
            <tr>
              <td
                className="logo__box border border-dark"
                style={{ background: games[game].away_color }}
              >
                {games[game].away_rank !== null ? (
                  <span className="away__rank">{`${games[game].away_rank}`}</span>
                ) : (
                  ""
                )}
                <img
                  className="team__logo"
                  src={`/logos/${games[game].away_id}.png`}
                />
                <span className="away__record">{`${games[game].away_record}`}</span>
              </td>
            </tr>
            <tr>
              <td className="border border-dark game__box">
                {`${games[game].away_team}`}
                <br />
                {`${games[game].time} - ${(() => {
                  switch (games[game].outlet) {
                    case "NFL NET":
                      return "NFLN";
                    case "CW NETWORK":
                      return "CW";
                    default:
                      return games[game].outlet;
                  }
                })()}`}
                <br />
                {`${games[game].home_team}`}
              </td>
            </tr>
            <tr>
              <td
                className="logo__box border border-dark"
                style={{ background: games[game].home_color }}
              >
                {games[game].home_rank !== null ? (
                  <span className="home__rank">{`${games[game].home_rank}`}</span>
                ) : (
                  ""
                )}
                <img
                  className="team__logo"
                  src={`/logos/${games[game].home_id}.png`}
                />

                <span className="home__record">{`${games[game].home_record}`}</span>
              </td>
            </tr>
          </table>
        );
      }
    }
    if (streamList.length === 0) {
      streamList.push(<p className="text-white">No Games</p>);
    }
    let streams = streamList.map((stream) => <span>{stream}</span>);
    return streams;
  }

  function handleDateChange(e) {
    setDate(formatDate(new Date(e.target.value)));
    //setDate(formatDate(new Date(e.target.value).toLocaleDateString()));
  }

  function handleTZChange(e) {
    setTimezone(e.target.value);
  }

  // This following section will display the table with the games of individuals.
  return (
    <div>
      <div className="row g-0">
        <div className="text-start my-auto">
          <select
            className="btn dropdown-toggle btn-light m-3 text-start"
            type="button"
            value={formatDateForGrid(date)}
            onChange={handleDateChange}
          >
            {getDropdownDates()}
          </select>
          <select
            className="btn dropdown-toggle btn-light m-3 text-start"
            type="button"
            value={timezone}
            onChange={handleTZChange}
          >
            <option className="btn-light" value="E">
              Eastern Time
            </option>
            <option className="btn-light" value="C">
              Central Time
            </option>
            <option className="btn-light" value="M">
              Mountain Time
            </option>
            <option className="btn-light" value="P">
              Pacific Time
            </option>
          </select>
        </div>
      </div>
      <div className="table-responsve text-nowrap container-fluid">
        <h2 className="text-white">
          Linear TV Schedule for {formatDateForGrid(date)}
        </h2>
        <table className="table table-bordered border-dark table-striped table-fixed position-sticky">
          <tbody>
            <tr>
              <th className="date__corner text-wrap" scope="col">
                {formatDateForGrid(date)}
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("ABC") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/abc.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("CBS") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/cbs.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("FOX") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/fox.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("NBC") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/nbc.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("ESPN") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/espn.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("ESPN2") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/espn2.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("ESPNU") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/espnu.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("FS1") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/fs1.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("CBSSN") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/cbssn.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("ACCN") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/accn.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("BTN") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/btn.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("PAC12") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/pac12.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("SECN") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/secn.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("LHN") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/lhn.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("NFL NET")
                    ? "channel"
                    : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/nfl.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("CW NETWORK")
                    ? "channel"
                    : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/cw.png" />
              </th>
              <th
                className={`${
                  uniqueOutlets.includes("truTV") ? "channel" : "channel-hidden"
                }`}
                scope="col"
              >
                <img className="channel__img" src="/logos/trutv.png" />
              </th>
            </tr>
            {gameList()}
          </tbody>
        </table>
        <h2 className="text-white">
          Streaming Schedule for {formatDateForGrid(date)}
        </h2>
        <div className="d-inline-block text-wrap">{streamList()}</div>
      </div>
    </div>
  );
}
