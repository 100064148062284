import React from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";

// We import NavLink to utilize the react router.
import { NavLink } from "react-router-dom";

// Here, we display our Navbar
export default function Navbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark justify-content-center">
        <h1 className="display-7 text-white">
          College Football TV Schedule Grid
        </h1>
      </nav>
    </div>
  );
}
